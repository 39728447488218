import React from "react"
import { Underlines } from "../Underlines/Underlines"
import AlexNatalia from "../../images/alex&natalia.jpg"
import {GiCheckMark} from 'react-icons/gi'
import "./injury.less"

export default () => {
    return (
        <section className="injuries" id="team">
            <article className="container">
                <div className="u-hide">
                    <Underlines />
                </div>
                <div className="underline-injury">
                    <div className="underline-one"></div>
                    <div className="underline-two"></div>
                    <div className="underline-one"></div>
                </div>

                <div className="injury-grid">
                    <article className="injury-lawyers">
                        <img src={AlexNatalia} alt="Alex & Natalia" />
                        <p className="name-tag alex">Alex Yadgarov</p>
                        <p className="name-tag natalia">Natalia Derin</p>
                    </article>

                    <article className="injury-content">
                        <h1>Abogados en Daño Personal de Rosedale</h1>
                        <h3>Un equipo legal de su confianza</h3>
                        <p>
                            Desde el 2009, Alex Yadgarov & Asociados ha trabado con una variedad de casos de daño personal para nuestros clientes en Queens, New York, incluyendo casos como demandas en responsabilidad civil y reclamaciones de muertes por negligencia. Si usted ha sufrido alguna lesión debido a la negligencia de una parte, usted podría tener derecho a una compensación monetaria. 
                            <br />
                            <br />
                            Nuestros clientes saben que pueden apoyarse en nosotros para luchar por sus derechos.
                        </p>
                    </article>
                </div>

                <div className="injury-point-container">
                    <div className="point-wrapper">
                        <ul>
                            <li><span><GiCheckMark/></span> Millones ganados para clientes con lesiones</li>
                            <li><span><GiCheckMark/></span> Miles de casos de lesiones ganados</li>
                            <li><span><GiCheckMark/></span> Equipo legal galardonado</li>
                            <li><span><GiCheckMark/></span> 100% sin cargo a menos que gane</li>
                            <li><span><GiCheckMark/></span> Bufete de abogados de 5 estrellas</li>
                        </ul>
                        <a href="tel:212-365-4255" className='btn'>
                            Obtenga su consulta GRATIS ahora 
                            <span>
                                212-365-4255
                            </span> 
                        </a>
                    </div>
                </div>        
            </article>

        </section>
    )
}
