import * as React from "react"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// NAV + HERO + CONSULT FORM
import NavHero from '../components/Hero/Hero'
import './index.less'


//STATS
import Stats from '../components/Stats/Stats'
// GUIDANCE
import Guidance from '../components/Guidance/Guidance'
// REVIEWS
import Reviews from '../components/Reviews/Reviews'
// Practices
import Practices from '../components/PracticeAreas/Practice'
// Personal Injuries
import Injury from '../components/Injury/Injury'
// NEXT
import Next from '../components/Next/Next'
// Footer
import Footer from '../components/Footer/Footer'
// SEO
import SEO from '../components/seo'


const IndexPage = () => {

  React.useEffect(()=>{
    const underlines = document.querySelectorAll('.underlines');
    const underlinesSm = document.querySelectorAll('.underline-container');
    const underlinesContact = document.querySelectorAll('.underline-contact');
    const underlinesOne = document.querySelectorAll('.underline-one');
    const underlinesTwo = document.querySelectorAll('.underline-two');
    const underlinesInj = document.querySelector('.underline-injury');

    let options = {
      root: document.querySelector('#scrollArea'),
      rootMargin: '0px',
      threshold: 1.0
    }

    const observer = new IntersectionObserver((entries)=>{
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0){
          if(entry.target.classList.contains("underline-one")){
            entry.target.style.animation = "anim3 0.65s forwards linear"; 
          }
          else if(entry.target.classList.contains("underline-two")){
            entry.target.style.animation = "anim3 0.5s forwards linear"; 
          }else{
            entry.target.style.animation = "anim 0.5s forwards linear"; 
          }
        }else{
          entry.target.style.color = "none";
        }
      })
    }, options);

    underlines.forEach(underline => {
      observer.observe(underline);
    })
    underlinesSm.forEach(underline => {
      observer.observe(underline);
    })
    underlinesContact.forEach(underline => {
      observer.observe(underline);
    })
    observer.observe(underlinesInj);

    underlinesOne.forEach(underline => {
      observer.observe(underline)
    })
    
    underlinesTwo.forEach(underline => {
      observer.observe(underline)
    })
  }, [])

  return (
    <>
      <SEO title="Alex Yadgarov" />
      <NavHero />
      <div className="bck">
        <Stats/>
        <Guidance/>
        <Reviews />
        <Practices/>
      </div>
      <div className="bck">
        <Injury />
      </div>
      <Next/>
      <Footer/>
    </>
  )
}

export default IndexPage
