import car from "../../images/car-accidents.jpg";
import sf from "../../images/slip-fall.jpg";
import worker from "../../images/worker-related.jpg";
import product from "../../images/product-liability.jpg";
import bus from "../../images/bus-accidents.jpg";
import train from "../../images/train-accidents.jpg";
import medicaid from "../../images/medicaid.jpg";
import construction from "../../images/Construction.jpg";
import truck from "../../images/truck-accident.jpg";
import Bicycle from "../../images/Bicycle.jpg"
import Pedestrian from "../../images/Pedestrian.jpg"
import Wrongful from "../../images/Wrongful.jpg"

export default [
    {
        id: 1,
        title: "Lesiones por accidentes auto",
        img: car ,
        description: "Nuestros abogados de Alex Yadgarov & Associates saben lo dolorosas que pueden ser las lesiones por accidentes automovilísticos. Después de haber pasado por el estrés de recibir tratamiento para sus síntomas, es posible que también se pregunte cómo recuperar los artículos perdidos o si puede tener derecho a una compensación. : Nuestros abogados de accidentes automovilísticos en Rosedale se enorgullecen de servir a Queens y los distritos circundantes. Estamos comprometidos a aliviar el estrés de nuestros clientes gestionando sus casos y adaptándonos a sus necesidades personales."
    },
    {
        id: 2,
        title: "Lesiones por resbalones y caídas y tropiezos y caídas",
        img: sf ,
        description: "Si ha tenido un accidente de resbalón y caída, no está solo. Más de un millón de lesiones por resbalones y caídas requieren atención médica de emergencia cada año en los Estados Unidos, más de 2000 personas cada día. El tratamiento por accidentes puede acumularse, ya sea una factura médica o días de trabajo perdidos. : Nuestros abogados compasivos de Alex Yadgarov & Associates ayudan a nuestros clientes en Rosedale, así como en Queens y los demás distritos de Nueva York, a obtener la compensación para la que están calificados para que puedan avanzar rápidamente hacia la recuperación."
    },
    {
        id: 3,
        title: "Lesiones con el trabajo",
        img: worker ,
        description: "Navegar por una lesión en el lugar de trabajo puede ser confuso. Después de haber tratado sus lesiones, es posible que se pregunte cómo recibir compensación y quién es la parte responsable. : En Nueva York, las lesiones sufridas en el trabajo están cubiertas por la indemnización laboral, pero un tercero puede ser considerado responsable. Alex Yadgarov & Associates puede revisar el caso y ayudarlo a buscar una compensación."
    },
    {
        id: 4,
        title: "Lesiones de accidentes de peatones",
        img: Pedestrian ,
        description: "Si lo atropelló un automóvil mientras cruzaba la calle, caminaba por la acera o en un estacionamiento en la ciudad de Nueva York y luego sufrió lesiones personales debido a la negligencia de otra persona, consulte a un abogado para comprender sus derechos legales. Al conducir cerca de peatones, los automovilistas tienen la responsabilidad de estar atentos a ellos y tener precaución. Los abogados de accidentes de peatones de Alex Yadgarov & Associates saben cómo asegurarse de que su caso se tome en serio para obtener la compensación que merece."
    },
    {
        id: 5,
        title: "Lesiones por accidentes de autobús",
        img: bus ,
        description: "Las lesiones de autobús son graves y merecen el tipo especial de atención que nuestros abogados de accidentes de autobús de Nueva York pueden brindar. Gracias al bullicio y la congestión de la ciudad de Nueva York, los accidentes de autobús son cada vez más comunes. : Ya sea que haya sido atropellado por un autobús o lesionado mientras viajaba, necesita el equipo adecuado para representar su caso. Alex Yadgarov & Associates puede ayudar a las víctimas de accidentes de autobús a obtener una compensación por su sufrimiento."
    },
    {
        id: 6,
        title: "Lesiones por accidentes de tren",
        img: train ,
        description: "¿Ha resultado herido en un accidente de tren? Ya sea física o mental, es probable que sus lesiones sean graves y el proceso legal para buscar una compensación puede ser estresante. : Nuestros abogados de accidentes de tren en Queens y Rosedale conocen los entresijos de las demandas por lesiones personales relacionadas con este tipo de accidentes y están comprometidos con nuestros clientes, por lo que puede estar tranquilo sabiendo que está siendo representado de manera justa."
    },
    {
        id: 7,
        title: "Lesiones por accidentes de camiones",
        img: truck ,
        description: "Los accidentes de camiones son inevitables en una metrópolis congestionada como la ciudad de Nueva York. Nuestro bufete de abogados está bien equipado con el conocimiento y la experiencia para garantizar que obtenga la compensación y la representación legal que se merece. : Estamos dedicados a ayudarlo a usted y a su familia a luchar por lo que es legítimamente suyo con nuestro enfoque de litigio paso a paso. Nos aseguramos de mantenerlo actualizado con toda la información crítica sobre su caso y nos enorgullecemos de mantener un 100 % de transparencia con usted en todo momento durante su caso de accidente de camión."
    },
    {
        id: 8,
        title: "Lesiones por accidentes de bicicleta",
        img: Bicycle ,
        description: "Desafortunadamente, los conductores no siempre respetan el derecho de paso de los ciclistas. Nuestros abogados luchan por las personas lesionadas en colisiones automovilísticas y las ayudan a recuperar la máxima compensación por sus lesiones. Los casos más comunes que manejamos son los accidentes automovilísticos en los que un conductor golpea a un ciclista o peatón mientras cruza una intersección o ingresa a la carretera desde senderos para bicicletas fuera de la carretera."
    },
    {
        id: 9,
        title: "Abogado de muerte injusta",
        img: Wrongful ,
        description: "Nuestros abogados de muerte por negligencia en Rosedale entienden lo indescriptiblemente difícil que puede ser perder a un ser querido. El proceso de duelo se siente abrumador e interminable, tanto que armar una demanda puede parecer imposible. Si ha perdido a un ser querido por mala conducta o negligencia, nuestro equipo puede preparar su caso para que usted no tenga que hacerlo. Nada se puede comparar con recuperar a su familia, pero podemos luchar para asegurarnos de que los responsables rindan cuentas."
    },
    {
        id: 10,
        title: "Lesiones por accidentes de construcción",
        img: construction ,
        description: "Los accidentes en el lugar de trabajo son comunes si eres un trabajador de la construcción. Todos los días, usted está expuesto a entornos de trabajo peligrosos, maquinaria pesada y productos químicos peligrosos. Eso no implica que sea aceptable que sufras lesiones. Si usted ha estado involucrado en un accidente, nuestros abogados especializados en accidentes de construcción en Rosedale quieren asumir su caso y ayudarlo a volver a la vida normal."
    },
]