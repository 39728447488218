import React, {useState} from "react";
import './cta.less'
import Consult from '../Hero/Consult'
import {FaPhoneAlt} from "react-icons/fa"
import { IoIosClose } from "react-icons/io"

export default ()=>{
    const [modal, setModal] = useState(false);

    const openModal = ()=>{
        setModal(true);
    }
    const closeModal = ()=>{
        setModal(false);
    }

    return(
        <section className="cta">
            <div className="contact-container">
                <a href="tel:212-365-4255"><span><FaPhoneAlt /></span> 212-365-4255</a>
            </div>
        </section>

    )
}