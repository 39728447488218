import React from "react"
import {GiHamburgerMenu} from "react-icons/gi"
import {useState, useEffect} from "react"
import {FaPhoneAlt} from "react-icons/fa"
import "./navbar.less"
import Logo from "../../images/logo.svg"

export default()=>{
    const [navbar, setNavbar] = useState(false);
    const [toggle, setToggle] = useState(false)


    const changeNav = () => {
        if(window.pageYOffset >0){
            setNavbar(true);
        }else{
            setNavbar(false);
        }
    }

    const test = ()=>{
        window.scrollTo({
            top: 0,
            left: 0
        })
    }

    const scroll = (e)=>{
        e.preventDefault();
        const id = e.currentTarget.getAttribute('href').slice(1);
        const element = document.getElementById(id);
        const navHeight = document.querySelector('.car-navbar').getBoundingClientRect().height;
        const linksHeight = document.querySelector('.links-container').getBoundingClientRect().height;
        let position = element.offsetTop - navHeight;

        if(navHeight > 130){
            position += linksHeight;
        }

        window.scrollTo({
            top: position,
            left:0,
        });
        setToggle(false);
    }
    

    useEffect(() => {
        window.addEventListener('scroll', changeNav);
    }, [])


    return(
        <nav className={`car-navbar ${navbar? "active" : ""} ${toggle? "toggle" : ""}`}> 
            <section>
                <article className="logo">
                    <a href="#hero" onClick={(e)=>{scroll(e)}}>
                        <img src={Logo} alt="Alex Yadgarov & Associates" />
                    </a>
                    <div id="nav-toggler">
                        <GiHamburgerMenu className="toggle-icon" onClick={()=>{setToggle(!toggle);}}/>
                    </div>
                </article>
                <article className={`links-container ${toggle? "toggle": ""}`}>
                    <ul className="links">
                        <li>
                            <a href="#cases" onClick={(e)=>{scroll(e)}} className="link">Casos</a>    
                        </li>
                        <li>
                            <a href="#reviews" onClick={(e)=>{scroll(e)}} className="link">Reseñas</a>    
                        </li>
                        <li>
                            <a href="#team" onClick={(e)=>{scroll(e)}} className="link">Conozca a nuestro equipo</a>    
                        </li>
                        <li>
                            <a href="#practice" onClick={(e)=>{scroll(e)}} className="link">Areas de práctica</a>
                        </li>
                    </ul>
                    <article className="appointment-mini">
                        <p>Obtenga su consulta gratis ahora</p>
                        <h3 className="number"><a href="tel:212-365-4255">
                            <span><FaPhoneAlt/></span> 212-365-4255</a></h3>
                    </article>
                </article>
                <article className="appointment">
                    <p onClick={test}>Consulta Gratis</p>
                    <h3 className="number"><a href="tel:212-365-4255"><span><FaPhoneAlt/></span> 212-365-4255</a></h3>
                </article>
            </section>
        </nav>
    )
}